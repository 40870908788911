import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBasket32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M26.2 10.1c-.1-.8-.8-1.4-1.5-1.4h-4v-1c0-.1 0-.4-.1-.7-.1-.5-.3-1-.5-1.5-.7-1.4-2.1-2.2-4.1-2.2-2 0-3.3.8-4.1 2.2-.4.8-.6 1.6-.6 2.2v1H7.5c-.8 0-1.5.6-1.6 1.4L4.5 27.3c-.1.8.5 1.4 1.3 1.4h20.3c.8 0 1.4-.6 1.3-1.4zm-4.1 3.6c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8m-10-6c0-.1 0-.3.1-.6.1-.4.2-.9.4-1.3C13.2 4.7 14.3 4 16 4s2.8.7 3.5 1.8c.2.4.4.8.4 1.3 0 .3.1.5.1.6v1h-7.9zM26.2 28H5.8c-.4 0-.6-.3-.6-.7l1.4-17.2c0-.4.4-.8.8-.8h3.9v1.8c-1.2.2-2.2 1.2-2.2 2.5 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-1.2-.9-2.3-2.1-2.5V9.3h7.9V11.2c-1.2.2-2.2 1.2-2.2 2.5 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-1.3-.9-2.3-2.1-2.5V9.3h4c.4 0 .8.4.8.8l1.3 17.2c.1.4-.2.7-.5.7M13.5 13.7c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8" /></SvgIcon>;
};
const Memo = memo(SvgBasket32);
export default Memo;