import deepmerge from '@mui/utils/deepmerge';
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { DEFAULTCOLOR_TEXT_DISABLED } from '../default/palette';
import type { Device } from '../default';
import { generateDefaultThemeOptions } from '../default';
import { fonts } from './fonts';

const COLOR_PRIMARY_MAIN = '#a51140';
const COLOR_SECONDARY_MAIN = '#e1929e';
const DEFAULTCOLOR_TEXT_DARK = '#555555';
const DEFAULTCOLOR_TEXT_LIGHT = '#ffffff';
const DEFAULTCOLOR_TEXT_TRANSPARENT_DARK = 'rgba(85, 85, 85, 0.65)';
const DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT = 'rgba(255, 255, 255, 0.65)';
const DEFAULTCOLOR_COMMON_LIGHT = '#f4f4f4';

/** Define theme object.
 * See here for more info: https://mui.com/customization/theming/ */
const themeOptions: ThemeOptions = {
  // https://mui.com/customization/palette/
  palette: {
    primary: {
      main: COLOR_PRIMARY_MAIN,
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
      contrastText: DEFAULTCOLOR_TEXT_LIGHT,
    },
    secondary: {
      main: COLOR_SECONDARY_MAIN,
    },
    grey: {
      main: '#DEDEDE',
      light: DEFAULTCOLOR_COMMON_LIGHT,
      dark: '#909994',
    },
    text: {
      disabled: DEFAULTCOLOR_TEXT_DISABLED,
      primary: DEFAULTCOLOR_TEXT_DARK,
      secondary: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      dark: DEFAULTCOLOR_TEXT_DARK,
      darkTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      light: DEFAULTCOLOR_TEXT_LIGHT,
      lightTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
    footer: {
      main: DEFAULTCOLOR_COMMON_LIGHT,
      contrastText: DEFAULTCOLOR_TEXT_DARK,
    },
  },
  typography: {
    fontFamily: fonts.primary.style.fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: '',
    },
    MuiButton: {
      styleOverrides: {
        root: ({
          theme: {
            unstable_sx: sx,
            palette: { grey },
          },
        }) =>
          sx({
            ':disabled': { bgcolor: grey.main, color: grey.dark },
          }),
      },
    },
  },
};

/** Merge with default theme options and create a theme instance
 * See here for more info: https://mui.com/customization/theming/#api */
const theme = (device: Device) =>
  createTheme(deepmerge(generateDefaultThemeOptions(device), themeOptions));

// eslint-disable-next-line import/no-default-export
export default theme;
