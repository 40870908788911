import localFont from 'next/font/local';

const primary = localFont({
  src: [
    { path: './fonts/poppins-light-webfont.woff', weight: '300', style: 'normal' },
    { path: './fonts/poppins-regular-webfont.woff', weight: '400', style: 'normal' },
    { path: './fonts/poppins-medium-webfont.woff', weight: '500', style: 'normal' },
    { path: './fonts/poppins-semibold-webfont.woff', weight: '600', style: 'normal' },
    { path: './fonts/poppins-bold-webfont.woff', weight: '700', style: 'normal' },
  ],
  variable: '--font-primary',
});

export const fonts = {
  primary,
};
